.twitt_card {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 0px 65px 20px 50px;
}

.twitt_card > div {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 1;
  flex-grow: 1;
}

.twitter-tweet {
  position: relative;
  max-height: 100%;
  max-width: 100%;
  flex-shrink: 1;
}
