@import "../components/global.module.css";

.activityBox {
  position: relative;
  display: flex;
  width: 100%;
  padding: 12px 25px;
  border-bottom: 1px solid rgba(15, 125, 87, 0.5);
}

.coverContainer {
  position: relative;
  height: 156px;
  width: 126px;
  margin-bottom: 0px;
  border-radius: 10px;
  overflow: hidden;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.rightContainer {
  display: flex;
  flex-direction: column;
  height: 156px;
  margin-left: 8px;
  flex-grow: 0;
  overflow: hidden;
}

.headline {
  height: 40px;
  margin-bottom: 10px;
  width: 100%;
  display: -webkit-box; /* Required for multi-line truncation */
  -webkit-box-orient: vertical; /* Required for multi-line truncation */
  -webkit-line-clamp: 2; /* Number of lines to display before truncating */
  line-clamp: 2;
  overflow: hidden; /* Hide any overflow text */
  text-overflow: ellipsis; /* Add ellipsis to indicate text overflow */
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.408px;
  color: var(--dark-green);
}

.link {
  text-decoration: none;
}

.newestMessage {
  height: 40px;
  width: 100%;
  display: -webkit-box; /* Required for multi-line truncation */
  -webkit-box-orient: vertical; /* Required for multi-line truncation */
  -webkit-line-clamp: 2; /* Number of lines to display before truncating */
  line-clamp: 2;
  overflow: hidden; /* Hide any overflow text */
  text-overflow: ellipsis; /* Add ellipsis to indicate text overflow */
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.408px;
  color: #525253;
}

.dot {
  flex-grow: 1;
  display: flex;
  align-items: center;
  height: 22px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.messagesCount {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: var(--dark-green);

  span {
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.5px;
    color: #ffffff;
  }
}
.avatarsContainer {
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 34px;
  overflow-x: scroll;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
