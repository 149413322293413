.navDiscussions__div {
  position: relative;
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  flex-shrink: 0;
  border-bottom: 1px solid #d9d9d9;
  margin-top: 12px;
  margin-bottom: 12px;
}

.navDiscussions__allIcon {
  height: 40px;
  padding-right: 12px;
  border-right: 1px solid #d9d9d9;
  cursor: pointer;
}

.navDiscussions__allIcon:focus {
  outline: none;
}

.navDiscussions__peopleContainer {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  padding-left: 12px;
  overflow-x: scroll;
  flex-grow: 1;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.navDiscussions__peopleContainer::-webkit-scrollbar {
  display: none;
}

.navDiscussions__search {
  flex-grow: 1;
  position: relative;
  right: 0px;
}
