.background {
  position: absolute;
  top: 0%;
  left: 0%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contentBox {
  width: 300px;
  height: 300px;
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  width: 185px;
  padding: 10px;
}

.button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 30px;

  background: #ffffff;
  border: 1px solid #484849;
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.408px;
  color: #484849;
}
