.welcome_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 70px 20px;
}

.box {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  height: 400px;
  border: 1px solid var(--green-main);
  border-radius: 20px;
}

.upperBox {
  display: flex;
  justify-content: center;
  height: 50%;
  width: 100%;
  background: #fff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.lowerBox {
  height: 50%;
  width: 100%;
  padding: 20px;
  background-color: var(--green-main);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  h1 {
    background-color: transparent;
    color: #fff;
    font-family: "Perpetua";
    font-weight: 700;
    font-size: 42px;
    line-height: 42px;
    letter-spacing: -0.408px;
    text-align: center;
  }
}

.logo {
  position: relative;
  width: 100%;
  max-width: 400px;
  object-fit: cover;
  bottom: -20px;
}

.buttonBox {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 60px;
}
