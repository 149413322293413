@import "../components/global.module.css";

.profileContainer {
  height: 100%;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.profilePicture {
  padding-bottom: 20px;
  border-bottom: 1px solid var(--gray);
}

.listContainer {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #000000;
  border-bottom: 1px solid var(--gray);
}

.buttonLink {
  text-decoration: none;
  color: #000000;
}

.listItem {
  position: relative;
  width: 100%;
  border-top: 1px solid var(--gray);
  color: black;
}
.listItem > a {
  color: black !important;
}

.listItemButton {
  display: flex;
  align-items: center;
  border: none;
  width: 100%;
  height: 100%;
  padding: 8px 0px;
  text-align: left;
  background-color: transparent;
  letter-spacing: -0.408px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #000000;

  .icon {
    font-size: 18px;
  }

  span {
    margin-right: 8px;
  }
}

.bottomContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 15px;
}

.button {
  height: 36px;
  padding: 0 20px;
  min-width: 120px;
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid #2d5f57;
  border-radius: 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 36px;
  text-align: center;
  letter-spacing: -0.408px;
  color: #484849;

  span {
    margin-left: 8px;
  }
}

.statusContainer {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.status {
  font-weight: 600;
  padding-bottom: 10px;
}

.premium {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--dark-green);
  font-size: 18px;
}
