@import ".././components/global.module.css";

.mainContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.topNav {
  position: fixed;
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  padding-left: 15px;
  background-color: #fff;
  z-index: 1;
}

.contentContainer {
  position: relative;
  width: 100%;
  padding: 50px 20px 20px 20px;
}

.languagesContainer {
  padding-top: 40%;
}

.sectionHeader {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 15px;
  text-align: center;
}

.sectionDescription {
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 0px;
  span {
    font-weight: 500;
    color: var(--dark-green);
  }
}

.languageOptionsContainer {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 30px;
}

.parentCluster {
  position: relative;
  width: 100%;
  padding-top: 15px;
}

.clusterSelected {
  font-weight: 700;
  transform: scale(1.03);
}

.subcategoriesOptionsContainer {
  display: flex;
  flex-wrap: wrap;
}

.itemContainer {
  padding-right: 5px;
  padding-top: 5px;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.submitButton {
  padding: 8px 70px;
  background-color: black;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: -0.408px;
  border: none;
  border-radius: 30px;
}

.submitButton:disabled {
  background-color: rgb(73, 73, 73);
}
