*,
body {
  margin: 0;
  padding: 0;
}

.feed {
  position: relative;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}

.feed::-webkit-scrollbar {
  display: none;
}

.content_container {
  position: relative;
  overflow: hidden;
  scroll-snap-align: start;
  outline: none;
}

.content_container::-webkit-scrollbar {
  display: none;
}

@media (max-width: 480px) {
  .feed {
    height: calc(100% - 52px);
    /* 52px top header feed */
    width: 100%;
  }

  .content_container {
    width: 100%;
    height: 100%;
    scroll-snap-align: start;
    margin-bottom: 20px;
  }
}

@media (min-width: 481px) {
  .feed {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.react-swipeable-view-container {
  height: 100%;
}

.feed__centeredMessage {
  text-align: center;
}

.lds-ellipsis div {
  background-color: #507f6e !important;
}
