.lastCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  height: 100%;
  width: 100%;
  padding: 30px;
  border-radius: 20px;
  background-color: #ffff;
  border: 1px solid #0f5940;

  text-align: center;
  margin-bottom: 15px;

  h3 {
    text-align: center;
    font-size: 22px;
    line-height: 1.4;
    color: #0f5940;
    font-weight: 600;
  }

  p {
    text-align: center;
    font-size: 14px;
    line-height: 1.4;
    color: #4e4e4e;
  }
}

.logo {
  margin-top: 6px;
}
