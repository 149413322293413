.background {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background: rgba(18, 18, 18, 0.4);
  z-index: 2;
}

.dialogBox {
  display: flex;
  flex-direction: column;
  width: 70%;
  min-width: 200px;
  max-width: 300px;
  background-color: #fff;
  border: 1px solid var(--dark-green);
  border-radius: 10px;
  overflow: hidden;
}

.input {
  width: 100%;
  text-align: center;
  padding: 15px 10px;
  border: none;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.408px;
  color: #000000;
  padding: 15px 25px;
}
:focus {
  outline: none;
}
input::placeholder {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #939191;
}

.dialogButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: none;
  border-top: 1px solid rgba(217, 217, 217, 0.7);
  height: 38px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.408px;
  background-color: #fff;
}

.dialogButton:disabled {
  color: #939191;
}

.blue {
  color: var(--green-main);
}
