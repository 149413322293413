.groupsContainer {
  position: relative;
}
.groups__infoMessage {
  position: relative;
  padding: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.groups__infoMessage > span {
  font-size: 14px;
}

.groups__box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30%;
  width: 100%;
}
