@media (max-width: 480px) {
  .container {
    position: fixed;
    max-height: calc(100% - 105px);
    border-bottom: 1px solid var(--gray);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: white;
  }
}

@media (min-width: 481px) {
  .container {
    position: relative;
  }
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 1;
}

.list {
  width: 100%;
  flex-grow: 1;
  overflow-y: auto;
}
.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(180deg);
}
.infoMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  font-size: 14px;
  overflow: auto;
}
