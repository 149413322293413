.groupNameContainer {
  position: relative;
  padding: 20px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 100px;
}

.input {
  border: none;
  outline: none;
  width: 250px;
  border-bottom: 1px solid black;
  margin-bottom: 30px;
  font-size: 16px;
  padding-bottom: 3px;
  padding-left: 5px;
  padding-right: 5px;
}
