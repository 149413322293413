@import "../components/global.module.css";

.button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 80px;
  padding: 8px 16px;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: -0.408px;
  color: var(--dark-green);
  border: 1px solid var(--dark-green);
  background-color: #fff;
  border-radius: 30px;

  span {
    margin-right: 5px;
  }
}

.selected {
  background-color: var(--dark-green);
  color: #fff;
}
