.singleDiscussion__discussions {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  overflow-y: auto;
}

.singleDiscussion__flexGrow {
  flex-grow: 1;
  width: 100%;
}
