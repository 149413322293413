@import "../components/global.module.css";

.sectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.headline {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #121212;
  margin-bottom: 10px;
  margin-top: 10px;
}

.box {
  margin-bottom: 10px;
}

.label {
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #727274;
  margin-bottom: 0px;
}

.input,
.disabledInput {
  width: 230px;
  height: 26px;
  padding-left: 10px;
  padding-right: 10px;
  background: #ffffff;
  border-radius: 15px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #121212;
}

.input {
  border: 1px solid var(--light-green);
}
.input:focus {
  outline: 1px solid var(--dark-green);
}

.input_error {
  border: 1px solid var(--red-error);
}
.input_error:focus {
  outline: 1.5px solid var(--red-error);
}

.disabledInput {
  border: 1px solid #d9d9d9;
}
.error_message {
  color: var(--red-error);
}
.buttonDiv {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 50px;
}
