.pricingList_container {
  height: 100%;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 50px;
}

.alreadyPremium {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 150px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 31px;
  color: var(--dark-green);
}

.logo {
  width: 80px;
  height: auto;
}
