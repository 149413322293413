.buttonPremium {
  padding: 10px 0;
  width: 90%;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.408px;
  background-color: var(--dark-green);
  color: white;
  border: none;
}
