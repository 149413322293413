.background {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.container {
  width: 60%;
  max-width: 600px;
  top: 50%;
  left: 50%;
  text-align: center;

  h1 {
    color: #507f6e;
    font-size: 36px;
    margin-bottom: 20px;
  }

  h2 {
    color: #727274;
    font-size: 16px;
    margin-bottom: 20px;
  }
}
.logo {
  position: relative;
  top: -20px;
  width: 120px;
}
