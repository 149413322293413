@import "../../components/global.module.css";

.avatarBox {
  position: relative;
  padding-right: 12px;
}

.avatar {
  cursor: pointer;
}

.avatar:focus {
  outline: none;
}

.avatarActive {
  border-radius: 30px;
  box-shadow: 0 0 6px #0f7d57;
}
