.container {
  position: relative;
  width: 100%;
}

.searchListContainer {
  position: relative;
  width: 100%;
}

.inputDiv {
  width: 100%;
  padding: 8px 15px;
}

.input {
  width: 100%;
  outline: none;
  border: none;
  font-size: 16px;
  border-bottom: 1px solid black;
  color: black;
}
