.community__people {
  position: relative;
  max-width: 1000px;
  margin: auto;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.loading {
  position: relative;
  top: 40px;
  width: 10px;
  margin: auto;
}

.bottomInView {
  height: 5px;
  width: 100%;
}
