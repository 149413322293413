@media (min-width: 481px) {
  .mainContainer {
    display: flex;
    justify-content: left;
    margin-left: 15px;
  }
}

@media (max-width: 480px) {
  .menuContainer {
    position: sticky;
    display: flex;
    align-items: center;
    background-color: #fff;
    top: 0px;
    left: 0px;
    height: 52px;
    width: 100%;
  }
}

.logo {
  position: relative;
  padding: 0px 20px;
}

.menuItemsContainer {
  position: relative;
  display: flex;
}

@media (max-width: 480px) {
  .menuItemsContainer {
    flex-grow: 1;
    justify-content: flex-end;
    padding-right: 20px;
    height: 34px;
  }
}

@media (min-width: 481px) {
  .menuItemsContainer {
    flex-direction: column;
    justify-content: center;
  }
}

.active {
  color: #0f7d57;
}

@media (min-width: 481px) {
  .menuItem {
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 25px;
    cursor: pointer;
  }

  .searchIconBigScreen {
    height: 30px;
    stroke-width: 1.8;
  }

  .menuItemName {
    margin-left: 15px;
    font-size: 16px;
  }
}

.discussionsIconBox {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  flex-shrink: 0;
}

@media (max-width: 480px) {
  .discussionsIconBox {
    margin-left: 4px;
  }
}

.discussionIcon {
  height: 100%;
  width: 100%;
}

.discussionsNotificationDot {
  position: absolute;
  top: 0px;
  right: -1px;
  height: 10px;
  width: 10px;
  background-color: #0f7d57;
  border-radius: 50%;
}

@media (min-width: 481px) {
  .searchPanelBigScreen {
    position: absolute;
    z-index: 2;
    width: 350px;
    top: 0px;
    bottom: 0px;
    left: 60px;
    background-color: white;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-right: 2px solid #efeef0;
  }
}
