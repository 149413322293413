.typeComment__div {
  position: relative;
  display: flex;
  align-items: end;
  justify-content: center;
  bottom: 0px;
  height: auto;
  width: 100%;
}
.typeCommnet__sendButton {
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}
.typeComment__getPremiumContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  bottom: 0px;
  height: auto;
  width: 100%;
}
.getPremiumContainer__text {
  margin-top: 4px;
  font-size: 12px;
}
