.div {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
}

.content {
  position: relative;
  padding: 150px 50px;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
}

.error {
  color: #0f7d57;
  font-weight: 600;
  font-size: 20px;
}

.button {
  padding: 10px 15px;
  font-weight: 600;
  color: #fff;
  background: #0f7d57;
  border: none;
  border-radius: 20px;
}
