.button_in_out {
  background-color: var(--green-main);
  color: #fff;
  width: 250px;
  height: 40px;
  border: none;
  border-radius: 30px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 26px;
  letter-spacing: -0.408px;
}
