.headerSection {
  position: relative;
  display: flex;
  padding-bottom: 10px;
}

.avatarContainer {
  flex-shrink: 0;
  position: relative;
  height: 120px;
  width: 120px;
  border-radius: 50%;
  overflow: hidden;

  img {
    height: 100%;
    background-size: contain;
  }
}

.infoContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 0px 20px;
}

.infoContainer > div {
  text-align: center;
  padding-bottom: 2px;
  word-break: break-all;
}

.name {
  font-weight: 600;
}

.followStatus {
  font-size: 12px;
  color: var(--dark-green);
}
