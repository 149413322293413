.swipeable_wrapper {
    position: absolute;
    height: 100%;
    width: 100%;
}

.swipeable_wrapper:focus {
    outline: none;
}

.swipeable_card {
    position: absolute;
    height: 100%;
    width: 100%;
    overflow: clip;
    outline: none;
    border-radius: 20px;
}

.react-swipeable-view-container::-webkit-scrollbar {
    display: none;
}

.dot__container {
    position: absolute;
    display: flex;
    bottom: 8px;
    left: 50%;
    transform: translateX(-50%);
}

.dot {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #fff;
    margin-right: 6px;
    border: 1px solid #8D8D92;
}

.dot__active {
    background-color: #8D8D92;
}