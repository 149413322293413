.contact__div {
  display: flex;
  align-items: center;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #d9d9d9;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.408px;
}

.contact__infoDiv {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.contact__nameContainer {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  margin-left: 8px;
  margin-right: 8px;
  word-break: break-all;
}

.contact__name {
  font-weight: 500;
  margin-right: 8px;
}

.contact__UserName {
  color: #727274;
}

.contact__children {
  color: #727274;
  max-width: 90px;
}
