.summaryCard {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 0px 58px 30px 20px;
}

.summaryCard__TextBackground {
    position: relative;
    overflow-y: auto;
    background-color: #fff;
    color: #000;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.summaryCard__TextBackground::-webkit-scrollbar {
    display: none;
}

.summaryCard__TextBackground>p {
    font-family: 'Poppins', sans-serif;
    text-align: left;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.408px;
}


/* @media(min-height:710px) {
    .summaryTextBackground>span {
        font-size: 16px
    }
}

@media(min-height:770px) {
    .summaryTextBackground>span {
        font-size: 17px
    }
}

@media(min-height:800px) {
    .summaryTextBackground>span {
        font-size: 18px
    }
} */