.comment {
  position: relative;
  display: flex;
  align-items: center;
  height: fit-content;
  margin-bottom: 12px;
}

.comment__container {
  position: relative;
  width: calc(100% - 85px);
  left: 25px;
}

.comment__avatarDiv {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 30px;
}

.comment__content {
  position: relative;
  margin-left: 40px;
  margin-top: 2px;
  max-width: 100%;
  word-wrap: break-word;
}

.comment__username {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: -0.408px;
  color: #000000;
}

.comment__time {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  letter-spacing: -0.408px;
  color: #939191;
}

.comment__text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  letter-spacing: -0.408px;
  color: #000000;
  margin-bottom: 0;
}

.comment__likeButton {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  right: 25px;
  top: 14px;
}

.comment__likeButton > p {
  font-size: 10px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #0f5940;
}

.comment__deletedDiv {
  position: relative;
  display: flex;
  align-items: center;
  height: 30px;
  width: 100%;
  margin-left: 25px;
  margin-bottom: 12px;
  flex-shrink: 0;
}

.comment__deleted {
  font-style: italic;
}
