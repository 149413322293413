@import "../components/global.module.css";
.container {
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 8px 25px;
  border-top: 1px solid var(--gray);
  border-bottom: 1px solid var(--gray);
}

.border {
  border-right: 1px solid var(--dark-green);
}
