.container {
  display: flex;
  width: 100%;
  border-bottom: 1px solid black;

  input {
    flex-grow: 1;
    outline: none;
    border: none;
    font-size: 16px;
  }
}
