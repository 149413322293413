.button {
  width: 202px;
  height: 36px;
  background: #0f5940;
  border-radius: 30px;
  border: none;

  font-weight: 600;
  font-size: 12px;
  letter-spacing: -0.408px;
  color: #ffffff;
}

.button:disabled {
  opacity: 0.6;
}
