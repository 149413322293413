.menuContainer {
  position: fixed;
  background-color: #efeef0;
}

@media (min-width: 769px) {
  .menuContainer {
    height: 100%;
    width: 220px;
    z-index: 1;
  }
}

@media (max-width: 768px) {
  .menuContainer {
    height: 100%;
    width: 60px;
    z-index: 1;
  }
}

@media (max-width: 480px) {
  .menuContainer {
    height: 55px;
    width: 100%;
    bottom: 0px;
    border-radius: 20px 20px 0 0;
    z-index: 0;
  }
}

.logoContainer {
  width: 55px;
}

.logoContainer svg {
  width: 100%;
  height: 100%;
}

@media (min-width: 769px) {
  .logoContainer {
    margin-left: 15px;
  }
}

.menuItemsContainer {
  position: relative;
  display: flex;
}

@media (min-width: 769px) {
  .menuItemsContainer {
    flex-direction: column;
    margin-top: 30px;
    margin-left: 15px;
  }
}

@media (max-width: 768px) {
  .menuItemsContainer {
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
  }
}

@media (max-width: 480px) {
  .menuItemsContainer {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 0px;
  }

  .menuItemsContainer > a {
    margin-top: 0px;
  }
}

@media (min-width: 481px) {
  .menuItem {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 25px;
  }
}

@media (min-width: 769px) {
  .menuItem {
    display: flex;
    justify-content: left;
    align-items: center;
    width: 100%;
    margin-bottom: 25px;
  }
}

.active {
  color: #0f7d57;
}

.iconBox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
}

.iconBoxWithNotification {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 32px;
  height: 32px;
}

.icon {
  width: 100%;
  height: 100%;
}

.notificationDot {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -1px;
  right: 0px;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background-color: #0f7d57;
  line-height: 14px;

  span {
    color: white;
    font-size: 10px;
  }
}

@media (min-width: 769px) {
  .menuItemName {
    margin-left: 15px;
    font-size: 16px;
  }
}
