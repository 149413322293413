.box {
  display: flex;
  align-items: flex-end;
}

.boxActive {
  border-bottom: 1px solid black;
}

.input {
  outline: none;
  border: none;
  font-size: 16px;
}

.input__hidden {
  width: 0px;
}

.input__active {
  width: 100%;
}
