.sourceCardImg {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.7;
}

.sourceContainer span {
    display: inline-block;
    width: 95%;
    color: #fff;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
}

.sourceContainer span a {
    color: #fff;
    cursor: pointer;
    text-decoration: none;


}

.sourceContainer {
    position: absolute;
    bottom: 35%;
    width: 100%;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.2);
}