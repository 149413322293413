@import "../../components/global.module.css";

.overview {
  position: relative;
  overflow-y: auto;
  width: 100%;
  height: 100%;
}

.header {
  position: relative;
}

.addNewUser {
  position: relative;
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
  padding-left: 30px;
  padding-right: 20px;
  background-color: #fff;
  border: none;
  border-bottom: 1px solid var(--gray);

  span {
    margin-left: 8px;
    font-weight: 600;
    font-size: 12px;
    line-height: 26px;
    letter-spacing: -0.408px;
    color: var(--dark-green);
  }
}

.membersList {
  position: relative;
  overflow-y: auto;
}
