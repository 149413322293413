@import "../components/global.module.css";

.notificationContainer {
  position: relative;
  display: flex;
  width: 100%;
  padding: 10px 20px;
  border-bottom: 1px solid rgba(15, 125, 87, 0.5);
  background-color: white;
  transition: background-color 1s;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  flex-grow: 1;
}

.nameContainer {
  display: flex;
  width: 100%;
}

.name {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #000000;
  flex-grow: 1;
}

.timeAgo {
  font-weight: 400;
  font-size: 8px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #939191;
  width: 100px;
  text-align: right;
}

.notificationInfo {
  font-weight: 500;
  font-size: 10px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #121212;
}

@keyframes greenBackground {
  from {
    background-color: white;
  }
  to {
    background-color: rgb(218, 245, 218);
  }
}

.notRead {
  animation-name: greenBackground;
  animation-duration: 0.3s;
  background-color: rgb(216, 248, 216);
  animation-timing-function: ease-in-out;
}
