.button {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 8px 20px;
  border: none;
  border-bottom: 1px solid #d9d9d9;
  background-color: #fff;
  line-height: 22px;
  cursor: pointer;
}

.text {
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  align-items: start;
}

.name {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.408px;
  color: #000000;
}

.description {
  font-weight: 400;
  font-size: 12px;
  letter-spacing: -0.408px;
  color: #727274;
}
