.headlineCard__img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.headlineCard__date {
    position: absolute;
    top: 16.67px;
    right: 16px;
    color: #FFF;
    text-align: center;
    font-family: 'Poppins', 'sans-serif';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.408px;
    text-shadow:
        -0.5px -0.5px 0 #121212,
        0.5px -0.5px 0 #121212,
        -0.5px 0.5px 0 #121212,
        0.5px 0.5px 0 #121212;
}

.headlineCard__textBackground {
    position: absolute;
    bottom: 0px;
    width: 100%;
    background-color: rgba(18, 18, 18, 0.50);
    border-radius: 0px 0px 20px 20px;
    text-align: left;
    z-index: 1;
}

.headlineCard__textBackground span {
    padding: 16.5px 68px 28px 16px;
    display: inline-block;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: -0.408px;
}