.supportButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 90px;
  height: 32px;
  padding: 0px 10px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.408px;
  border-radius: 20px;
  border: none;
}

.follow {
  background-color: #0f5940;
  color: #fff;

  span {
    margin-left: 6px;
  }
}

.unfollow {
  background-color: #efeef0;
  color: black;
}
