@import "../components/global.module.css";
.container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
}

.nav {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-left: 25px;
  padding-right: 25px;
}

.buttonDiv {
  padding-left: 10px;
  padding-right: 10px;
}

.borders {
  border-left: 1px solid var(--dark-green);
  border-right: 1px solid var(--dark-green);
}
