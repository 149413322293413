@import "../components/global.module.css";

.button {
  height: 32px;
  width: 90px;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: -0.408px;
  border-radius: 20px;
  border: none;
}

.active {
  color: #fff;
  background-color: var(--dark-green);
  transition: 0.3s;
}

.inactive {
  color: var(--dark-green);
  background-color: transparent;
  transition: 0.3s;
}
