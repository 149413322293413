.div {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 20px;
  background-color: #fff;
  border: none;
  border-bottom: 1px solid #d9d9d9;
}

.groupName {
  margin-left: 30px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #000000;
}
