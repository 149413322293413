.container {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;
  z-index: 2;
  background-color: white;
}

.header {
  position: relative;
  top: 0px;
  width: 100%;
  padding: 10px 20px;
  display: flex;
  align-items: center;
}

.arrowBack {
  margin-right: 20px;
}

.contactsList {
  position: relative;
  overflow-y: auto;
  flex-grow: 1;
}

.footer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  flex-shrink: 0;
  background-color: #fff;

  button {
    width: 155px;
    height: 35px;
  }
}
