* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.animation__screen {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  overflow: hidden;
}

.animation__container {
  position: relative;
  background-color: #fff;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.animation__logoTop {
  position: absolute;
  width: 100%;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
}

.animation__topMenu {
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 2;
}

.animation__logoTop > div {
  font-family: "Coolvetica";
  font-size: 22px;
  font-weight: 600;
  padding-bottom: 60px;
  padding-top: 20px;
  color: #507f6e;
}

.animation__text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.animation__text > p {
  font-size: 16px;
  font-weight: 600;
  top: 40%;
  white-space: nowrap;
  overflow: hidden;
}

.animation__text > p::before {
  content: attr(data-text);
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  transform: translate(-50%);
  color: transparent;
  overflow: hidden;
  animation: loadingText 4s ease-in infinite;
}

@keyframes loadingText {
  0%,
  10%,
  100% {
    width: 0;
    color: #507f6e;
  }

  70%,
  90% {
    width: 84px;
    color: #507f6e;
  }
}

.littlePlane {
  position: absolute;
  max-width: 100px;
  max-height: 100px;
}

.topPlane .littlePlane {
  left: -100px;
  rotate: -15deg;
  animation-name: topPlaneAnimation;
  animation-duration: 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-delay: 1s;
}

.bottomPlane .littlePlane {
  right: -100px;
  top: 45vh;
  transform: scale(-1, 1);
  animation-name: bottomPlaneAnimation;
  animation-duration: 3.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-delay: 2.5s;
}

@keyframes topPlaneAnimation {
  0% {
    transform: translateX(-100%) translateY(45vh) rotate(-25deg);
    opacity: 0;
  }
  10% {
    transform: translateX(0vw) translateY(36vh) rotate(-35deg);
    opacity: 1;
  }
  40% {
    transform: translateX(40vw) translateY(28vh) rotate(5deg);
  }
  70% {
    transform: translateX(70vw) translateY(40vh) rotate(35deg);
  }
  90% {
    transform: translateX(90vw) translateY(50vh) rotate(20deg);
    opacity: 1;
  }
  100% {
    transform: translateX(110vw) translateY(55vh) rotate(25deg);
    opacity: 0;
  }
}

@keyframes bottomPlaneAnimation {
  0% {
    transform: translateY(0) translateX(0) rotate(-40deg) scale(-1, 1);
  }

  45% {
    transform: translateY(18vh) translateX(-55vw) rotate(0deg) scale(-1, 1);
  }

  70% {
    transform: translateY(13vh) translateX(-80vw) rotate(20deg) scale(-1, 1);
  }

  100% {
    transform: translateY(3vh) translateX(calc(-100vw - 100px)) rotate(10deg)
      scale(-1, 1);
  }
}
