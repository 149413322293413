@import "../components/global.module.css";

.container {
  border-top: 1px solid rgba(15, 125, 87, 0.5);
  position: relative;
}

.readInview {
  position: absolute;
  height: 10px;
  width: 100%;
}

.inView {
  height: 5px;
  width: 100%;
}

.fetchNext {
  padding: 20px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
