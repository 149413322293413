.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.imgContainer {
  position: relative;
  width: 100px;
  height: 100px;
  margin-top: 20px;
  margin-bottom: 10px;
  border-radius: 50%;
  overflow: hidden;

  img {
    height: 100%;
    width: auto;
    background-size: contain;
  }
}

.uploadButton {
  height: 36px;
  padding: 0 20px;
  background: #ffffff;
  border: 1px solid #2d5f57;
  border-radius: 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 36px;
  text-align: center;
  letter-spacing: -0.408px;
  color: #484849;
}
